import {useEffect} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import {LoginHandler} from "src/core/authentication/handlers";
import {useRecoilState} from "recoil";
import {ModalGlobalState} from "src/core/common/components/modules/modal/modalContext";
import useSite from "src/core/sites/hooks/useSite";
import useAgeGate from "src/core/user/hooks/useAgeGate";

export default function useStrongholdOnBoarding() {
  useAgeGate();
  const router = useRouter();
  const [modalGlobalState, setModalGlobalState] = useRecoilState(ModalGlobalState);
  const [, authApi] = useAuthentication();

  const site = useSite();
  const options = site.getOptions();
  const isOnBoarding = options.strongholdOnBoardingEnabled();

  useEffect(() => {
    if (!isOnBoarding || !authApi.isInitialized()) return;

    setModalGlobalState({
      ...modalGlobalState,
      allowedToClose: false,
    });

    if (!authApi.isLoggedIn()) {
      router.push(routes.signup);
      return authApi.addListener(
        new LoginHandler(() => {
          router.push({
            pathname: routes.paymentMethods,
          });
        })
      );
    } else {
      router.push({
        pathname: routes.paymentMethods,
      });
    }
  }, [isOnBoarding, authApi.isInitialized()]);

  return {isOnBoarding};
}
